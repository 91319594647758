import React, { ChangeEvent, MouseEvent, useRef, useState } from 'react';
import { Link } from 'src/components/Link';
import pathGenerator from 'src/helpers/pathGenerator';
import SearchIcon from './search-icon-simple.svg';
import styles from './SearchFormSimple.module.scss';

export const SearchFormSimple: React.FC<any> = () => {
    const [searchPhrase, setSearchPhrase] = useState('');
    const formElement = useRef<HTMLFormElement>(null);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchPhrase(event.target.value);
    };

    const onClearFormClick = (event: MouseEvent<HTMLInputElement>) => {
        setSearchPhrase('');
    };
    const handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        if (formElement.current) {
            formElement.current.submit();
        }
    };

    const isActive = searchPhrase !== '';
    return (
        <form
            data-testid="search-form"
            action={pathGenerator.getExternalSearchPath()}
            className={styles.form}
            ref={formElement}
            data-ga-action="search_google_menu"
            data-ga-category="header"
            data-ga-label="abctv"
            data-ga-search-query-name="q"
            data-ga4-event-name="header"
            data-ga4-event-details-1="search_google_menu"
            data-ga4-search-type="form_submit"
            data-ga4-link-title="search_google"
            data-ga4-link-url="https://www.abcnyheter.no/sok/"
        >
            <input
                type="search"
                placeholder="Søk i ABC Nyheter"
                name="q"
                autoComplete="off"
                data-testid="search-input"
                value={searchPhrase}
                onChange={onChange}
                className={styles.searchInput}
            />
            {isActive && (
                <span
                    className={styles.cleanInput}
                    onClick={onClearFormClick}
                />
            )}
            <button
                type="submit"
                className={styles.submitButton}
                data-testid="search-submit-button"
                onClick={handleSubmit}
            >
                Søk
            </button>
        </form>
    );
};

export const SearchLinkSimple = () => (
    <Link
        className={styles.searchLink}
        to={pathGenerator.getExternalSearchPath()}
        title="sok"
        isExternal={true}
        target="_self"
        data-ga-action="search_google_menu"
        data-ga-category="header"
        data-ga-label="abctv"
        data-ga4-event-name="header"
        data-ga4-event-details-1="search_google_menu"
        data-ga4-link-title="search_google"
        data-ga4-link-url="https://www.abcnyheter.no/sok/"
    >
        <img src={SearchIcon} alt="sok" />
    </Link>
);
