import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';

import { NavLink } from 'react-router-dom';
import { Link } from 'src/components/Link';
import pathGenerator from 'src/helpers/pathGenerator';
import {
    SearchFormSimple,
    SearchLinkSimple,
} from '../SearchForm/SearchFormSimple';
import abcnLogo from './ABC-Nyheter-logo.svg';
import styles from './Header.module.scss';

export const Header: FC<Pick<RouteComponentProps, 'location'>> = ({
    location,
}) => {
    const hash = location.hash;
    const [isSticky, setIsSticky] = React.useState(false);
    let lastScroll = 0;
    let isHeaderSticky = isSticky;

    const handleScroll = () => {
        const scrollUpDelta =
            document.body.getBoundingClientRect().top - lastScroll;
        lastScroll = document.body.getBoundingClientRect().top;
        if (isHeaderSticky && scrollUpDelta < -10) {
            isHeaderSticky = false;
            setIsSticky(false);
        } else if (!isHeaderSticky && scrollUpDelta > 20) {
            isHeaderSticky = true;
            setIsSticky(true);
        }
    };

    useEffect(() => {
        if (!hash) {
            return;
        }
        const scrollToEl = document.querySelector(hash);
        if (scrollToEl && scrollToEl.scrollIntoView) {
            setIsSticky(false);
            scrollToEl.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.key]);
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    return (
        <header className={isSticky ? styles.sticky : ''}>
            <TopHeader />
            <SubHeader />
        </header>
    );
};

const TopHeader = () => (
    <div className={styles.topHeaderBG}>
        <div className={`pageWrapper ${styles.topHeader}`}>
            <BrandLogoLink />
            <div className={styles.searchLinkWrapper}>
                <SearchLinkSimple />
            </div>
            <div className={styles.searchFormWrapper}>
                <SearchFormSimple />
            </div>
        </div>
    </div>
);

const onTVClick = () => {
    window.scrollTo({ top: 0 });
};

const SubHeader = () => (
    <div className={styles.subHeaderBG}>
        <nav
            className={`pageWrapper ${styles.navigation}`}
            data-ga-action="navigation"
            data-ga-category="abctv_header"
            data-ga4-event-name="abctv_header"
            data-ga4-event-details-1="navigation"
        >
            <NavLink
                to={pathGenerator.getFrontPath}
                className={styles.menuItemLinkFront}
                onClick={onTVClick}
                data-ga-label="tv"
                data-ga-url={pathGenerator.getFrontPath}
                data-ga4-event-details-2="tv"
                data-ga4-link-title="tv"
                data-ga4-link-url={pathGenerator.getFrontPath}
            >
                TV
            </NavLink>
            <NavLink
                className={styles.menuItemLink}
                to={pathGenerator.getFrontPathLatest}
                data-ga-label="siste"
                data-ga-url={pathGenerator.getFrontPathLatest}
                data-ga4-event-details-2="siste"
                data-ga4-link-title="siste"
                data-ga4-link-url={pathGenerator.getFrontPathLatest}
            >
                Siste
            </NavLink>
            <NavLink
                className={styles.menuItemLink}
                to={pathGenerator.getFrontPathPopular}
                data-ga-label="populaere"
                data-ga-url={pathGenerator.getFrontPathPopular}
                data-ga4-event-details-2="populaere"
                data-ga4-link-title="populaere"
                data-ga4-link-url={pathGenerator.getFrontPathPopular}
            >
                Populære
            </NavLink>
        </nav>
    </div>
);

const BrandLogoLink = () => {
    const linkTitle = 'ABC Nyheter';
    return (
        <Link
            className={styles.brandLink}
            to={'/'}
            title={linkTitle}
            isExternal={true}
            target="_self"
            aria-label="abcnyheter"
            data-ga-action="logo"
            data-ga-category="abctv_header"
            data-ga-label="abcnyheter"
            data-ga4-event-name="abctv_header"
            data-ga4-event-details-1="logo"
            data-ga4-link-title="abcnyheter"
            data-ga4-link-url="/"
        >
            <img className={styles.brandLogo} src={abcnLogo} alt={linkTitle} />
        </Link>
    );
};

export default Header;
