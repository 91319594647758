import React, { FC } from 'react';

import RedArrowRight from 'src/components/Footer/logos/RedArrowRight.svg';
import { categoryUrl } from 'src/helpers/linkCreator/linkCreator';
import { Link } from '../Link';
import { INavigationCategory } from './Footer';
import styles from './styles/Navigation.module.scss';

interface INavigationProps {
    navigationCategories: INavigationCategory[];
}

const renderCategory = (category: INavigationCategory, index: number) => {
    const { text, subcategories, urlname } = category;
    const url = categoryUrl(urlname);
    const isMer = urlname === 'mer';
    return (
        <div className={styles.singleCategory} key={`${text}_${index}`}>
            {isMer ? (
                <div className={styles.categoryTitle}>{text}</div>
            ) : (
                <Link
                    to={url}
                    className={styles.categoryTitle}
                    tracking={{
                        category: 'footer',
                        action: 'navigation',
                        label: `${text}`,
                    }}
                    data-ga4-event-name={'footer'}
                    data-ga4-event-details-1={'categories'}
                    data-ga4-event-details-2={text}
                    data-ga4-link-title={text}
                    data-ga4-link-url={url}
                    isExternal={true}
                >
                    {text}
                    <img
                        className={styles.redArrow}
                        src={RedArrowRight}
                        alt="Red Arrow"
                    />
                </Link>
            )}
            <ul>
                {subcategories &&
                    subcategories.map((subCat, index2) =>
                        renderSubcategories(subCat, index2, url, text)
                    )}
            </ul>
        </div>
    );
};

const renderSubcategories = (
    subCat: string,
    index: number,
    url: string,
    eventDetails2: string
) => {
    return (
        <li
            className={styles.subCat}
            data-ga-label={'properName'}
            key={`${subCat}_${index}`}
        >
            <Link
                to={`${url}/${subCat}`}
                tracking={{
                    category: 'footer',
                    action: 'navigation',
                    label: `${subCat}`,
                }}
                data-ga4-event-name={'footer'}
                data-ga4-event-details-1={'categories'}
                data-ga4-event-details-2={eventDetails2}
                data-ga4-link-title={subCat}
                data-ga4-link-url={`${url}/${subCat}`}
                isExternal={true}
            >
                {subCat}
            </Link>
        </li>
    );
};

const Navigation: FC<INavigationProps> = props => {
    return (
        <div className={styles.navigation}>
            {props.navigationCategories.map((category, index) =>
                renderCategory(category, index)
            )}
        </div>
    );
};

export default Navigation;
