import React, { Component, JSXElementConstructor, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import getConfig from 'src/config';
import pathGenerator from 'src/helpers/pathGenerator';
import slugify from 'src/helpers/slugify';
import VideoTagsParser from 'src/helpers/VideoTagsParser';
import CategoryProvider from 'src/services/CategoryProvider';
import { WATCHED_VIDEOS_KEY } from 'src/services/Storage';
import { IJwVideo, IVideo } from 'src/types/video';
import styles from './ViprPlayerLazy.module.scss';

interface IViprPlayerLazyProps extends RouteComponentProps {
    video: IVideo;
    containerId: string;
    playListId: string;
}

interface IViprPlayerGA {
    id: string;
    name: string;
    events?: string[];
    customDimensionsMap?: {
        [key: number]: (video: IVideo) => string;
    };
}

export interface IViprPlayerPianoTracking {
    siteId: string;
    persistedQueryId: string;
    section: string;
}

interface IViprPlayer extends IViprPlayerLazyProps {
    autoplay: boolean;
    playerId: string;
    relatedPlayCallback: (event: IJwVideo) => void;
    ga: IViprPlayerGA[];
    ga4: IViprPlayerGA[];
    piano: IViprPlayerPianoTracking;
    options: {
        watchedVideosLocalStorageKey: string;
    };
    ads: {
        config: {
            enablePPID?: boolean;
            targeting: {
                pageType: string;
            };
        };
    };
    related: {
        file: string;
        displayMode: 'shelf';
        autoplaytimer: number;
        onclick: 'play';
        oncomplete: 'autoplay';
        shouldAutoAdvance: false;
        showButton: true;
    };
    eventsHandler: any;
}

interface IViprPlayerState {
    loaded: boolean;
    module: ReactNode | null;
}

const JW_PLAYER_ID = 'MXPdpgqy';

class ViprPlayerLazy extends Component<IViprPlayerLazyProps, IViprPlayerState> {
    public state = {
        loaded: false,
        module: null,
    };

    private abctvTracker: IViprPlayerGA = {
        id: getConfig().tracking.googleAnalytics.ga.id,
        name: 'abctv',
        customDimensionsMap: {
            16: video => video.site,
            17: video => video.tags,
        },
    };

    private ga4Tracking: IViprPlayerGA = {
        id: getConfig().tracking.googleAnalytics.ga4.id,
        name: 'abctv',
    };

    private pianoTracking: IViprPlayerPianoTracking = {
        siteId: getConfig().tracking.piano.siteId,
        persistedQueryId: getConfig().tracking.piano.persistedQueryId,
        section: 'abctv',
    };

    public render(): ReactNode {
        const { loaded, module } = this.state;
        const { video, playListId } = this.props;
        if (!module) {
            return <div className={styles.placeholder} />;
        }
        const loop = (
            vid: HTMLVideoElement,
            ctx: CanvasRenderingContext2D | null,
            canvas: HTMLCanvasElement
        ) => {
            if (!vid.paused && !vid.ended && ctx) {
                ctx.drawImage(
                    vid,
                    0,
                    0,
                    vid.videoWidth,
                    vid.videoHeight,
                    0,
                    0,
                    canvas.width,
                    canvas.height
                );
                setTimeout(() => loop(vid, ctx, canvas), 1000 / 30);
            }
        };

        const ViprPlayer = (module as unknown) as JSXElementConstructor<
            IViprPlayer
        >;
        const relatedUrl = this.getRelatedPlaylistUrl();

        const handler = (event: { eventName: string }) => {
            if (event.eventName === 'play' || event.eventName === 'resume') {
                const vid = document.getElementsByClassName(
                    'jw-video'
                )[0] as HTMLVideoElement;
                const canvas = document.getElementById(
                    'canvas'
                ) as HTMLCanvasElement;
                const ctx = canvas.getContext('2d');
                loop(vid, ctx, canvas);
            }
        };

        return (
            <div className={styles.placeholder}>
                {loaded && (
                    <>
                        <canvas id="canvas" className={styles.canvas} />
                        <ViprPlayer
                            eventsHandler={handler}
                            key={video.provider_id}
                            relatedPlayCallback={this.playRelatedVideo(
                                playListId
                            )}
                            playerId={JW_PLAYER_ID}
                            ga={[this.abctvTracker]}
                            ga4={[this.ga4Tracking]}
                            piano={this.pianoTracking}
                            autoplay={getConfig().player.autoplay}
                            related={{
                                file: relatedUrl,
                                displayMode: 'shelf',
                                autoplaytimer: 5,
                                onclick: 'play',
                                oncomplete: 'autoplay',
                                shouldAutoAdvance: false,
                                showButton: true,
                            }}
                            options={{
                                watchedVideosLocalStorageKey: WATCHED_VIDEOS_KEY,
                            }}
                            ads={{
                                config: {
                                    enablePPID: true,
                                    targeting: {
                                        pageType: 'abctv',
                                    },
                                },
                            }}
                            {...this.props}
                        />
                    </>
                )}
            </div>
        );
    }

    public componentDidMount() {
        import(/* webpackChunkName: "vipr" */
        /* webpackPrefetch: true */
        '@startsiden/vipr-player').then(module => {
            this.setState({
                loaded: true,
                module: module.ViprPlayer,
            });
        });
    }
    private getRelatedPlaylistUrl() {
        const { playListId } = this.props;
        const watchedVideoIds = localStorage.getItem(WATCHED_VIDEOS_KEY)
            ? (localStorage.getItem(WATCHED_VIDEOS_KEY) || '').replaceAll(
                  /[\"\[\]]/g,
                  ''
              )
            : '';
        return playListId
            ? `${
                  getConfig().apiRest
              }/JwPlayNext?playlistId=${playListId}&watchedVideoIds=${watchedVideoIds}&providerId=MEDIAID`
            : `${
                  getConfig().apiRest
              }/RelatedPlaylist?watchedVideoIds=${watchedVideoIds}&related_media_id=MEDIAID`;
    }

    private playRelatedVideo = (playListId: string) => (
        video: IJwVideo
    ): boolean => {
        const { item } = video;
        const siteName = VideoTagsParser.getSiteName(video);

        this.props.history.push(
            playListId
                ? pathGenerator.getPlayListPath({
                      playListId,
                      videoId: item.mediaid,
                      urlSafeTitle: slugify(item.title),
                  })
                : pathGenerator.getVideoPath({
                      videoId: item.mediaid,
                      categoryName: CategoryProvider.getBySite(siteName)
                          .urlSafeName,
                      urlSafeTitle: slugify(item.title),
                  })
        );
        return true;
    };
}

export default withRouter(ViprPlayerLazy);
